/* Global Styles */
:root {
  --primary-gradient: linear-gradient(45deg, #7c3aed, #ec4899);
  --text-primary: #111827;
  --text-secondary: #4b5563;
  --bg-white: #ffffff;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--text-primary);
  line-height: 1.5;
}

/* Header Styles */
.site-header {
  /* position: fixed; */
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
  padding: 1rem 2rem;
  box-shadow: var(--shadow-sm);
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.nav-links {
  display: flex;
  gap: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: var(--text-primary);
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Hero Section */
.hero-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background: linear-gradient(135deg, rgba(124, 58, 237, 0.1), rgba(236, 72, 153, 0.1));
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: var(--text-secondary);
  margin-bottom: 2rem;
}

.cta-button {
  background: var(--primary-gradient);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: var(--shadow-md);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

/* About Section */
.about-section {
  padding: 6rem 2rem;
  background: var(--bg-white);
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

/* .about-text {
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.03rem;
} */

/* Product Introduction Section */
.product-intro-section {
  text-align: center;
  padding: 5rem 2rem;
  background: var(--bg-white);
}

.product-intro-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.product-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.eatzap-logo {
  height: 3rem;
  /* Matches the title size */
  width: auto;
}

.product-title {
  font-size: 2.75rem;
  font-weight: 700;
  color: var(--text-primary);
}

.product-description {
  max-width: 600px;
  margin: 1rem auto 0;
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--text-secondary);
  background: rgba(0, 0, 0, 0.05);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
}

/* Problem Statement Section */
/* Problem Statement Section */
.problem-section {
  padding: 6rem 7rem;
  background: linear-gradient(135deg, rgba(124, 58, 237, 0.07), rgba(236, 72, 153, 0.07));
  position: relative;
}

.problem-content {
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

/* Problem Imagery Layout */
.problem-imagery {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  position: relative;
}

/* Image Styling */
.queue-image {
  max-height: 600px;
  border-radius: 12px;
  box-shadow: var(--shadow-lg);
  position: relative;
}

/* Stats Overlay - Slightly Overlapping Image */
.stats-overlay {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  padding: 2rem;
  box-shadow: var(--shadow-lg);
  margin-left: -14rem;
  position: relative;
  z-index: 2;
  max-width: 60%;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s ease-out forwards 0.3s;
}

/* Title inside Stats */
.stats-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primary-gradient);
  margin-bottom: 1.5rem;
  text-align: left;
}

/* Stats Items */
.stats-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stats-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  text-align: left;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: white;
  box-shadow: var(--shadow-sm);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

/* Pop Effect on Hover */
.stats-item:hover {
  transform: translateX(12px);
  box-shadow: var(--shadow-md);
}

/* Stats Icons */
.stats-icon {
  font-size: 1.5rem;
  color: var(--primary-gradient);
  transition: transform 0.3s ease;
}

.stats-item:hover .stats-icon {
  transform: scale(1.1);
}

/* Animation */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Eatzap Solution Section */
.eatzap-section {
  padding: 0 6rem;
  background: linear-gradient(135deg, rgba(124, 58, 237, 0.07), rgba(236, 72, 153, 0.07));
  position: relative;
  text-align: center;
}

.eatzap-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
/* Solution Header */
.solution-header {
  max-width: 900px;
  margin: 0 auto 3rem;
}

.section-title {
  font-size: 1.7rem;
  font-weight: 700;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 1rem;
}

/* .section-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: var(--primary-gradient);
  margin: 8px auto 0;
  border-radius: 2px;
} */

.eatzap-intro {
  font-size: 1.2rem;
  color: #555;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

.eatzap-intro::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: var(--primary-gradient);
  margin: 8px auto 0;
  border-radius: 2px;
}

/* Solution Image - Full Width */
.solution-image {
  max-width: 800px;
  height: 800px;
  margin-top: -1rem;
  object-fit: contain;
  border-radius: 16px;
  box-shadow: var(--shadow-lg);
}

/* Benefits Grid */
.benefits-grid-left {
  display: grid;
  margin-right: -3rem;
  grid-template-columns: repeat(1, 3fr);
  gap: 2rem;
  max-width: 1100px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
}

.benefits-grid-right {
  display: grid;
  margin-left: -3rem;
  grid-template-columns: repeat(1, 3fr);
  gap: 2rem;
  max-width: 1100px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
}

/* Benefits Column Styling */
.benefits-column {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: var(--shadow-sm);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.benefits-title {
  font-size: 1.1rem;
  font-weight: 700;
  color: var(--primary-gradient);
  margin-bottom: 1rem;
}

.benefits-list {
  list-style: none;
  padding: 0;
}

.benefits-list li {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.9rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Benefits Hover Effects */
.benefits-column:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.benefits-list li:hover {
  color: var(--primary-gradient);
  transform: translateX(8px);
}

/* Icons Styling */
.icon {
  font-size: 1.4rem;
  color: var(--primary-gradient);
  transition: transform 0.3s ease;
}

.benefits-list li:hover .icon {
  transform: scale(1.1);
}

/* Showcase Section */
/* Success Story Section */
.showcase-section {
  padding: 3rem 2rem;
  background: linear-gradient(135deg, rgba(236, 72, 153, 0.07), rgba(124, 58, 237, 0.07));
  text-align: center;
}

/* Case Study Introduction */
.case-study-intro {
  font-size: 1rem;
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.8;
  color: #555;
}

.case-study-intro::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background: var(--primary-gradient);
  margin-top: 12px;
  margin-bottom: -8px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
}

/* Image Gallery */
.image-gallery {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 4rem auto;
  max-width: 1500px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: var(--shadow-lg);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 600px;
  aspect-ratio: 1 / 1;
  /* Keeps width while reducing height */
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
  border-radius: 20px;
}


.gallery-item:hover {
  transform: scale(1.08);
  box-shadow: var(--shadow-md);
}

.gallery-item:hover .gallery-image {
  transform: scale(1.12);
}

/* Impact Metrics Section */
.impact-metrics {
  margin-top: 5rem;
}

.metrics-title {
  font-size: 2rem;
  font-weight: 700;
  color: white;
  z-index: 2;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px #303030; /* Adjust values as needed */
}
/* Metrics Grid */

/* Metrics Section */
.impact-metrics {
  margin-top: -10rem;
}

.metrics-grid {
  display: grid;
  max-width: 1200px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  margin: 0 auto;
}

.metric-card {
  padding: 2rem;
  text-align: center;
  background: var(--bg-white);
  border-radius: 1rem;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: 800;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 1rem 0;
}

.metric-card:hover {
  transform: translateY(-6px);
  box-shadow: var(--shadow-md);
  transition: ease-in-out 0.5s;
}

/* Expansion Plans*/
.expansion-section {
  padding: 100px 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-light);
}

.expansion-content {
  max-width: 1000px;
  text-align: center;
  width: 100%;
  padding: 60px;
  border-radius: 20px;
  background: white;
  box-shadow: var(--shadow-md);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.expansion-content:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

/* Buttons */
.contact-button {
  margin-top: 20px;
  padding: 14px 28px;
  font-size: 16px;
  font-weight: 600;
  justify-self: center;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: white;
  color: #555;
  transition: all 0.3s ease;
}

.contact-button:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-lg);
}

/* Expansion Section */
.expansion-text {
  font-size: 16px;
  line-height: 1.8;
  color: var(--text-secondary);
  text-align: center;
  margin-bottom: 30px;
}

.contact-card {
  padding: 30px;
  border-left: 6px solid var(--accent-color);
  background: var(--primary-gradient);
  border-radius: 12px;
  text-align: center;
}

.contact-title {
  font-size: 20px;
  font-weight: 600;
  color: white;
}


/* Footer */
.site-footer {
  padding: 4rem 2rem;
  background: #111827;
  color: white;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
}

.footer-brand .footer-title {
  font-size: 1.5rem;
  font-weight: 700;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.footer-tagline {
  color: #9ca3af;
  margin-top: 0.5rem;
}

.links-list {
  list-style: none;
  margin-top: 1rem;
}

.links-list li {
  margin-bottom: 0.75rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.links-list li:hover {
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Mobile Menu */
.mobile-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-icon {
  color: var(--text-primary);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-content,
.about-content,
.product-intro-content,
.benefits-column,
.gallery-item,
.metric-card {
  animation: fadeIn 1s ease-out forwards;
}

/* Mobile and Tablet Responsiveness */
@media (max-width: 1024px) {
  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-top: -5rem;
  }

  .benefits-column {
    padding: 1.5rem;
  }

  .solution-image {
    max-height: 300px;
  }

  .image-gallery {
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem;
  }

  .gallery-item {
    max-width: 100%;
    aspect-ratio: 16/9;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .metrics-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .metric-title {
    font-size: 1rem;
  }

  .problem-imagery {
    flex-direction: column;
    gap: 2rem;
  }

  .stats-overlay {
    margin-left: 0;
    max-width: 100%;
  }

  .queue-image {
    max-height: 300px;
    width: 100%;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .site-header {
    padding: 0.75rem 1rem;
  }

  .header-content {
    padding: 0 1rem;
  }

  .nav-links {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  .hero-section {
    padding: 1rem;
    min-height: 80vh;
  }

  .hero-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .about-section,
  .problem-section,
  .eatzap-section,
  .showcase-section {
    padding: 3rem 1rem;
  }

  .image-gallery {
    margin: 2rem auto;
  }

  .gallery-item {
    aspect-ratio: 16/9;
    height: auto;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product-description {
    font-size: 1rem;
    padding: 0.75rem 1rem;
  }

  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2rem;
  }

  .expansion-content {
    padding: 30px;
  }

  .contact-button {
    width: 100%;
  }

  .queue-image {
    max-height: 250px;
    width: 100%;
    object-fit: cover;
  }

  .problem-section {
    padding: 3rem 1rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 1.75rem;
  }

  .cta-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .benefits-column {
    padding: 1rem;
  }

  .benefits-title {
    font-size: 1.25rem;
  }

  .benefits-list li {
    font-size: 0.875rem;
  }

  /* .metric-value {
    font-size: 2rem;
  } */

  .gallery-item {
    border-radius: 12px;
    aspect-ratio: 4/3;
  }

  .gallery-image {
    border-radius: 12px;
  }

  .queue-image {
    max-height: 200px;
    width: 100%;
    object-fit: cover;
  }

  .problem-section {
    padding: 2rem 1rem;
  }

  .stats-overlay {
    padding: 1rem;
  }

  .stats-title {
    font-size: 1.25rem;
  }

  .stats-item {
    padding: 0.75rem;
  }

  .showcase-section {
    padding: 2rem 1rem;
  }

  .image-gallery {
    margin: 1.5rem auto;
    gap: 1.5rem;
  }
}

/* Additional Small Screen Optimizations */
@media (max-width: 360px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .queue-image {
    max-height: 180px;
  }

  .stats-overlay {
    padding: 0.75rem;
  }

  .stats-title {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  .stats-item {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .stats-icon {
    font-size: 1.25rem;
  }

  .gallery-item {
    aspect-ratio: 1/1;
  }

  .gallery-image {
    border-radius: 8px;
  }

  .image-gallery {
    gap: 1rem;
    margin: 1rem auto;
  }
}

/* Mobile Menu Styles */
.nav-links.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  padding: 1rem;
  box-shadow: var(--shadow-md);
  animation: slideDown 0.3s ease-out forwards;
  z-index: 1000;
}

.nav-links.active a {
  padding: 0.75rem 1rem;
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
}

.nav-links.active a:hover {
  background: rgba(124, 58, 237, 0.1);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update mobile menu button styles */
.mobile-menu {
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease;
}

.mobile-menu:hover {
  background-color: rgba(124, 58, 237, 0.1);
}

.mobile-menu:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(124, 58, 237, 0.2);
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .eatzap-section {
    padding: 0 2rem;
  }

  .eatzap-box {
    flex-direction: column;
  }

  .benefits-grid-left,
  .benefits-grid-right {
    margin: 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    width: 100%;
  }

  .solution-image {
    max-width: 100%;
    height: auto;
    max-height: 500px;
    margin: 2rem 0;
  }

  .solution-header {
    margin: 0 auto 2rem;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .eatzap-section {
    padding: 0 1rem;
  }

  .section-title {
    font-size: 1.4rem;
  }

  .eatzap-intro, .case-study-intro {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .benefits-grid-left,
  .benefits-grid-right {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .benefits-column {
    padding: 1.5rem;
  }

  .solution-image {
    max-height: 400px;
    margin: 1.5rem 0;
  }

  .benefits-list li {
    font-size: 0.85rem;
    padding: 0.5rem 0;
    gap: 0.75rem;
  }

  .icon {
    font-size: 1.2rem;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .section-title {
    font-size: 1.2rem;
    letter-spacing: 0.5px;
  }

  .eatzap-intro, .case-study-intro {
    font-size: 0.9rem;
  }

  .eatzap-intro::after, .case-study-intro::after {
    width: 60px;
    height: 3px;
    margin: 6px auto 0;
  }

  .benefits-column {
    padding: 1.25rem;
  }

  .benefits-title {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .benefits-list li {
    font-size: 0.8rem;
    padding: 0.4rem 0;
    gap: 0.5rem;
  }

  .icon {
    font-size: 1rem;
  }

  .solution-image {
    max-height: 300px;
    margin: 1rem 0;
    border-radius: 12px;
  }

  .benefits-column:hover {
    transform: translateY(-3px);
  }

  .benefits-list li:hover {
    transform: translateX(4px);
  }
}